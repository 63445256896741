<template>
  <div class="bidding_detail_wrapper">
    <!-- <a-breadcrumb>
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>招投标</a-breadcrumb-item>
      <a-breadcrumb-item>招投标详情</a-breadcrumb-item>
    </a-breadcrumb>-->
    <div class="commom_title">
      <a-row type="flex">
        <a-col :span="1">
          <div class="commom_line"></div>
        </a-col>
        <a-col :span="6">招投标信息详情</a-col>
      </a-row>
    </div>
    <div class="content">
      <div class="title">{{ data.title }}</div>
      <div class="details_table">
        <div class="cell">项目地址：{{ data.region }}</div>
        <div class="cell">信息来源：{{ data.source }}</div>
        <div class="cell">招标时间：{{ $route.query.time }}</div>
      </div>
      <div class="text-content" v-html="data.data"></div>
    </div>
  </div>
</template>

<script>
import { employmentEmploytemplateDemandById } from '@/api/workDemand'

export default {
  name: 'Detial',
  data () {
    return {
      data: {
        time: null
      }
    }
  },
  created () {
    this.init()
  },
  computed: {
    cityName () {
      const { province, city } = this.data
      return city ? `${province}-${city}` : province
    }
  },
  methods: {
    init () {
      this.$spin.show()
      const { id, time } = this.$route.query
      employmentEmploytemplateDemandById({
        time,
        id,
        tel: this.$store.getters.userInfo.phone
      })
        .then((res) => {
          this.data = res.data
        })
        .finally(() => {
          this.$spin.hide()
        })
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
.bidding_detail_wrapper {
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    padding: 0 143px;
    font-weight: 500;
    line-height: 33px;
    color: #333333;
    opacity: 1;
  }
  .lable {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    margin-top: 20px;
    .province {
      margin-right: 55px;
    }
  }
  .details_table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .cell {
      width: 33.3%;
      height: 60px;
      line-height: 60px;
      text-indent: 5px;
      margin-top: 20px;
      padding-left: 28px;
      border: 1px solid #deecff;
    }
  }
  .text-content {
    flex: 1;
    margin-top: 20px;
    min-height: 143px;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 18px;
    color: #898989;
    font-weight: 500;
    text-indent: 50px;
    table {
      width: 100%;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 0px;
      border-left-width: 0px;
      border-top-style: solid;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: solid;
      border-top-color: #efefef;
      border-right-color: #efefef;
      border-bottom-color: #efefef;
      border-left-color: #efefef;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 0px;
      margin-right: 0px;
      margin-left: 0px;
      tr > td {
        width: auto;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #efefef;
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: #efefef;
        margin: 0px;
        border-top-style: none;
        padding: 4px;
      }
    }
  }
}
</style>
